<template>
  <div>
    <!-- Start Content  -->
    <b-modal
      v-model="modal.isShow"
      :title="`${modal.id ? $t('TIER.UPDATE') : $t('TIER.CREATE')} ${$t(
        'TIER.OPERATOR'
      )}`"
      @ok="submitModal"
    >
      <b-form @submit.stop.prevent="submitModal" ref="form">
        <div class="row">
          <div class="col-6">
            <b-form-group :label="`${$t('MENU.ROLE')}:`">
              <b-select size="sm" v-model="modal.role_id">
                <b-select-option
                  v-for="dt in roles"
                  :key="`role-${dt.id}`"
                  :value="dt.id"
                  >{{ dt.role }}</b-select-option
                >
              </b-select>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group :label="`${$t('TABLE.STATUS')}:`">
              <b-select size="sm" v-model="modal.status">
                <b-select-option
                  v-for="dt in statusList"
                  :key="`status-${dt.id}`"
                  :value="dt.id"
                  :state="validateState('status')"
                  >{{ dt.name }}</b-select-option
                >
              </b-select>
              <b-form-invalid-feedback>
                {{ $t("MODAL.FIELD_REQUIRED") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group :label="`${$t('TABLE.NAME')}:`">
              <b-form-input
                :placeholder="$t('TIER.ENTER_NAME')"
                v-model="$v.modal.name.$model"
                :state="validateState('name')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ $t("MODAL.FIELD_REQUIRED") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="`${$t('TABLE.USERNAME')}:`">
              <b-form-input
                :placeholder="$t('TIER.ENTER_USERNAME')"
                v-model="$v.modal.username.$model"
                :state="validateState('username')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ $t("MODAL.FIELD_REQUIRED") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group :label="`${$t('LOGIN.PASSWORD')}:`">
              <b-form-input
                :placeholder="$t('TIER.ENTER_PASSWORD')"
                type="password"
                v-model="$v.modal.password.$model"
                :state="validateState('password')"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ $t("MODAL.FIELD_REQUIRED") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :label="$t('TIER.CONFIRM_PASSWORD') + ':'">
              <b-form-input
                :placeholder="$t('TIER.ENTER_PASSWORD')"
                type="password"
                v-model="modal.confirm_password"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ $t("AUTHENTICATOR.VALIDATION_CONFIRM_PASSWORD") }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="ok()"
          >
            {{ $t("MODAL.OK") }}
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="float-right mr-2"
            @click="cancel()"
          >
            {{ $t("MODAL.CANCEL") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Start List  -->
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-3 mt-3 page-title">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">{{
            $t("MENU.OPERATOR_LIST")
          }}</span>
        </h3>
      </div>
      <div class="card-body pb-0">
        <div class="d-flex justify-content-between align-items-start pb-3">
          <div>
            <label class="form-label">{{ $t("TABLE.PER_PAGE") }}</label>
            <b-select
              size="sm"
              v-model="pagination.limit"
              @change="getOperator(1)"
            >
              <b-select-option value="25">25</b-select-option>
              <b-select-option value="50">50</b-select-option>
              <b-select-option value="100">100</b-select-option>
            </b-select>
          </div>
          <b-button
            v-if="getAccess.includes('tier--CREATE')"
            size="sm"
            class="mr-3"
            variant="primary"
            @click="showModal()"
            >{{ $t("TABLE.CREATE") }}</b-button
          >
        </div>

        <b-table class="bg-white" striped hover :fields="fields" :items="items">
          <!-- Set No Data when none of item -->
          <template #bottom-row="items" v-if="items.length === 0">
            <b-td :colspan="fields.length" class="text-center">{{
              $t("TABLE.NO_DATA")
            }}</b-td>
          </template>

          <!-- A virtual column -->
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(status)="data">
            {{ $t("TABLE." + data.item.status) }}
          </template>

          <template #cell(created_at)="data">
            {{ data.item.created_at | moment("DD-MM-YYYY HH:mm:ss") }}
          </template>

          <template #cell(action)="data">
            <b-button
              v-if="getAccess.includes('tier--UPDATE')"
              size="sm"
              class="mr-3"
              variant="success"
              @click="showModal(data.item)"
              >{{ $t("TABLE.UPDATE") }}</b-button
            >
            <b-button
              v-if="getAccess.includes('tier--DELETE')"
              size="sm"
              class="mr-3"
              variant="danger"
              @click="deleteOperator(data.item)"
            >
              {{ $t("TABLE.DELETE") }}
            </b-button>
          </template>
        </b-table>
        <!-- Start Pagination  -->
        <div class="overflow-auto">
          <b-pagination
            v-model="pagination.page"
            :per-page="pagination.limit"
            :total-rows="pagination.totalRow"
            aria-controls="my-table"
            @change="getOperator"
          ></b-pagination>
        </div>
        <!-- End Pagination  -->
      </div>
    </div>
    <!-- End List  -->
    <!-- End Content  -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import {
  GET_OPERATOR,
  CREATE_OPERATOR,
  UPDATE_OPERATOR,
  DELETE_OPERATOR
} from "@/core/services/store/operator.module";
import { GET_ROLE } from "@/core/services/store/role.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import { mapGetters } from "vuex";
import globalMixin from "@/core/plugins/mixins.js";

export default {
  mixins: [validationMixin, globalMixin],
  computed: {
    ...mapGetters(["downlineTier", "getAccess"])
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("TABLE.INDEX"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "role",
          label: this.$t("MENU.ROLE"),
          tdClass: "align-middle",
          thClass: "text-center"
        },
        {
          key: "name",
          label: this.$t("TABLE.NAME"),
          tdClass: "align-middle",
          thClass: "text-center"
        },
        {
          key: "username",
          label: this.$t("TABLE.USERNAME"),
          tdClass: "align-middle",
          thClass: "text-center"
        },
        {
          key: "status",
          label: this.$t("TABLE.STATUS"),
          tdClass: "align-middle text-center",
          thClass: "text-center"
        },
        {
          key: "action",
          label: this.$t("TABLE.ACTION"),
          tdClass: "align-middle action-column",
          thClass: "text-center"
        }
      ],
      items: [],
      roles: [],
      statusList: [
        { id: "ACTIVE", name: this.$t("TABLE.ACTIVE") },
        { id: "BANNED", name: this.$t("TABLE.BANNED") }
      ],
      modal: {
        id: "",
        role_id: 0,
        isShow: false,
        name: "",
        username: "",
        prev_username: "",
        password: "",
        confirm_password: "",
        status: "ACTIVE"
      },
      pagination: {
        limit: 25,
        page: 1,
        totalRow: 0
      }
    };
  },
  validations: {
    modal: {
      name: {
        required
      },
      username: {
        required
      },
      status: {
        required
      },
      password: {
        required: requiredIf(function () {
          return !this.modal.id;
        })
      }
    }
  },
  mounted() {
    this.getOperator();
    this.getRole();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.modal[name];
      return $dirty ? !$error : null;
    },
    getRole() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_ROLE)
        .then((e) => {
          this.roles = e;
        })
        .catch((err) => {
          Swal.fire(this.translateErrorFromCode(err), "", "error");
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    getOperator(page) {
      if (page) {
        this.pagination.page = page;
      }

      let body = {
        limit: this.pagination.limit,
        page: this.pagination.page
      };

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(GET_OPERATOR, body)
        .then((e) => {
          this.items = e.data;
          this.pagination.totalRow = e.totalRow;
          this.pagination.limit = e.limit;
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    showModal(dt) {
      this.modal.id = dt ? dt.id : "";
      this.modal.name = dt ? dt.name : "";
      this.modal.username = dt ? dt.username : "";
      this.modal.prev_username = dt ? dt.username : "";
      this.modal.password = dt ? dt.password : "";
      this.modal.confirm_password = dt ? dt.confirm_password : "";
      this.modal.status = dt ? dt.status : "ACTIVE";
      this.modal.isShow = true;
      this.modal.role_id = dt
        ? dt.role_id
        : this.roles.length > 0
        ? this.roles[0].id
        : 0;
    },
    submitModal(modalEvent) {
      // Prevent modal from closing
      modalEvent.preventDefault();
      // Trigger submit handler
      this.createOrUpdateOperator();
    },
    createOrUpdateOperator() {
      const $self = this;
      // Exit when the form isn't valid
      this.$v.modal.$touch();
      if (this.$v.modal.$anyError) {
        return;
      }

      if (this.modal.password != this.modal.confirm_password) {
        Swal.fire({
          text: this.$t("AUTHENTICATOR.VALIDATION_CONFIRM_PASSWORD"),
          type: "error",
          confirmButtonText: this.$t("MODAL.OK")
        });
        return;
      }

      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store
        .dispatch(this.modal.id ? UPDATE_OPERATOR : CREATE_OPERATOR, {
          username: this.modal.prev_username,
          data: {
            name: this.modal.name,
            username: this.modal.username,
            password: this.modal.password,
            status: this.modal.status,
            role_id: this.modal.role_id
          }
        })
        .then(() => {
          $self.modal.isShow = false;
          Swal.fire({
            text: this.modal.id
              ? this.$t("MODAL.UPDATE_SUCESSFULL")
              : this.$t("MODAL.CREATE_SUCESSFULL"),
            type: "success",
            confirmButtonText: this.$t("MODAL.OK")
          }).then(() => {
            $self.getOperator(1);
          });
        })
        .catch((err) => {
          Swal.fire({
            text: this.translateErrorFromCode(err),
            type: "error",
            confirmButtonText: this.$t("MODAL.OK")
          });
        })
        .then(() => {
          this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        });
    },
    deleteOperator(data) {
      Swal.fire({
        title: `${this.$t("MODAL.DELETE_CONFIRMATION")} "${data.username}" ?`,
        showCancelButton: true,
        confirmButtonText: this.$t("MODAL.OK"),
        cancelButtonText: this.$t("MODAL.CANCEL")
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
          this.$store
            .dispatch(DELETE_OPERATOR, {
              username: data.username
            })
            .then(() => {
              this.getOperator();
              Swal.fire({
                text: this.$t("MODAL.DELETE_SUCESSFULL"),
                type: "success",
                confirmButtonText: this.$t("MODAL.OK")
              });
            })
            .catch((err) => {
              Swal.fire({
                text: this.translateErrorFromCode(err),
                type: "error",
                confirmButtonText: this.$t("MODAL.OK")
              });
            })
            .then(() => {
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.page-title {
  min-height: auto !important;
}

::v-deep .action-column {
  min-width: 175px;
  text-align: center;
}

::v-deep .amount-column {
  width: 50px;
}
</style>
